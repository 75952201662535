.no-border {
	border:none;
}

.no-margin {

	&-t {
		margin-top:0px;
	}

	&-b {
		margin-bottom:0px;
	}

	&-all {
		margin: 0px;
	}
}

.no-padding {

	&-t {
		padding-top:0px;
	}

	&-b {
		padding-bottom:0px;
	}
}

.right {
	float:right;
}

.left {
	float:left;
}

.inline {
	display:inline-block;
}

.clickable {
	cursor: pointer;
}

.not-clickable {
	cursor: default !important;
}

.collapse-header {

	h2 {
		margin-bottom: 1rem;
	}

	.icon {
		float:right;
		margin-top:10px;
	}
}

.mt {
	&-5 {
		margin-top: 5px;
	}

	&-10 {
		margin-top: 10px;
	}

	&-20 {
		margin-top: 20px;
	}
}

.mr {
	&-5 {
		margin-right: 5px !important;
	}

	&-10 {
		margin-right: 10px !important;
	}

	&-20 {
		margin-right: 20px !important;
	}
}

.pt {
	&-5 {
		padding-top: 5px;
	}

	&-10 {
		padding-top: 10px;
	}

	&-20 {
		padding-top: 20px;
	}
}

.pr {
	&-5 {
		padding-right: 5px;
	}

	&-10 {
		padding-right: 10px;
	}

	&-20 {
		padding-right: 20px;
	}
}

.pad-25 {
	padding:25px;
}

.instructions {
	img {
		width:auto;
		max-width:100%;
	}

	li {
		font-weight:500;
		padding-left:15px;
		padding-bottom:10px;
	}
}

.tag {
	.fa-check {
		color:$green;
	}

	.fa-check-circle {
		color:#414F3E;
	}
}

.shade {
	background-color: #f1f1f1;
}

.error {
	em {
		color: #bf0711;
		font-size: 13px;
	}
}

svg {
	&.error {
		color: #bf0711;
	}
}

.text-right {
	text-align: right !important;
}

/* Vue Tag Overrides */
.vue-tags-input {
	max-width: none !important;

	.ti-input {
		padding:0px !important;
		border-radius:3px;

		.ti-new-tag-input-wrapper {
			margin:0px !important;

			input {
				border-bottom:none !important;
			}

			.ti-new-tag-input {
				min-height:2.9rem;
				border-bottom:none !important;
				border-radius: 0px !important;
			}
		}
	}

	.error & {
		.ti-input {
			border-color: #ed6347;
			background-color: #fbeae5;
		}
	}
}

/* Vue Datetime Picker */
.zero-brand .vdatetime-popup__header,
.zero-brand .vdatetime-calendar__month__day--selected > span > span,
.zero-brand .vdatetime-calendar__month__day--selected:hover > span > span {
	background: #DB7E26;
}

.zero-brand .vdatetime-year-picker__item--selected,
.zero-brand .vdatetime-time-picker__item--selected,
.zero-brand .vdatetime-popup__actions__button {
	color: #DB7E26;
}

/* Vue Tooltip */
.tooltip {
  display: block !important;
  z-index: 10000;
  max-width:300px;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}
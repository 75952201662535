@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

.comparison-chart {

  .table {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0rem;
    max-width: 1100px;
  }

  .table-cell {
    box-sizing: border-box;
    flex-grow: 1;
    width: 100%;
    padding: 0.8em 1.2em;
    overflow: hidden;
    list-style-type: none;
    outline: 1px solid #ddd;
    text-align: center;
    font-weight: 300;
    margin: {
      top: 1px;
      left: 1px;
    }
    &:first-child {
    	outline: 1px solid #fff;
      background: transparent;
    	@media only screen and (max-width : 768px) {
  			display: none;
  		}
    }
    &:nth-child(3) {
      outline: 1px solid #DB7E26;
    }
    &:nth-child(-n+3) {
    	padding: {
    		top: 40px;
    		bottom: 40px;
    	}
    }
    > h3 {
      font-size: 26px;
    	margin: {
    		top: 0;
    		bottom: 1rem;
    	}
    }
    >.price {
      margin: {
        top: 0;
        bottom: 3rem;
      }
      font-size: 15px;
    }
    &.cell-feature {
  	  text-align: left;
      font-size: 18px;
    }
    &.free {
      color: #DB7E26;
      a.btn {
        color: #DB7E26;
        &:hover {
          background-color: #DB7E26;
          border-color: #DB7E26;
          color: white;
        }
      }
    }
    &.premium {
      background-color: #DB7E26;
      color: #FFF;
      a.btn {
        color: #FFF;
        &:hover {
          background-color: #FFF;
          border-color: #FFF;
          color: #DB7E26;
        }
      }
    }
  }

  /* Table columns
  ================================== */
  .table-cell  {
    width: calc(33.33% - 1px);
    &.cell-feature {
  	  @media only screen and (max-width : 768px) {
  		  width: 100%;
  		  text-align: center;
  	  }
    }
  }

  .fa-check {
    color: #DB7E26 !important;
  }

  a.btn {
    border: 2px solid;
    padding: .6rem .9rem .6rem .9rem;
    font-weight: 400;
    text: {
      transform: uppercase;
      decoration: none;
    }
    @include transition(0.2s ease-in-out);
    @include border-radius(.3rem);
  }
}
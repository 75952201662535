.font-container {
	padding: 0.7em 1.0em;
	border: 1px solid #e4e4e4;
	border-radius: 10px;
	cursor: pointer;
	margin: 0px 10px 10px 0px;
	font-size:16px;

	&.active {
		background-color: $brand;
	}
}

.template-container {
	max-width: 25%;
	width: 25%;
	overflow: hidden;
	display: inline-block;

	label {
		padding: 0.7em 1.0em;
		border: 1px solid #e4e4e4;
		border-radius: 10px;
		cursor: pointer;
		margin: 0px 5% 10px 0px;
		font-size:14px;
		width: 95%;

		&.custom {
			background-color: #FFFFFF;
			color: #000000;
		}
	}
}

.header {
	h3 {
		margin: 0px;
		padding: 5px 15px;
		width: 100%;
		background-color: #F1F1F1;/*#DB7E26;*/
		color: #000;
		font-weight: normal;
		font-size: 16px;
		margin-bottom: 10px;
		border-bottom: 3px solid #e0e0e0;

		&.premium {
			color: #DB7E26;
		}
	}
}
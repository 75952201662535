// Variables
@import 'variables';

// Toggle
@import 'toggle';

// Layout
@import 'layout';

// General
@import 'general';

// Forms
@import 'forms';

// Chart
@import 'chart';

// Uptown
@import 'uptown/uptown';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Bubblegum+Sans|Butterfly+Kids|Concert+One|Emilys+Candy|Exo+2|Flavors|Happy+Monkey|Montserrat|Mountains+of+Christmas|Open+Sans|Oswald|Poppins|Princess+Sofia|Rakkas|Raleway|Roboto|Snowburst+One|Ubuntu|Yatra+One');
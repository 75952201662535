.container {
	padding-top:25px;
}

.vc-chrome {
	position: absolute;
	top: 57px;
	right: 0;
	z-index: 99;
}
.current-color {
	display: inline-block;
	width: 16px;
	height: 16px;
	background-color: #000;
	cursor: pointer;
	border:1px solid #e4e4e4;
}

.onboarding {
	background-image: url('/images/onboarding-bg.png');
	background-position: center;
	text-align: center;
	width:100%;
	height:100%;
	border:5px solid #fff;
	position: relative;

	.content {
		width:91%;
		position: absolute;
		border-radius:5px;
		background-color: rgba(255, 255, 255, 0.8);
		padding:25px;
		bottom:25px;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
	}
}

button {
	&.btn {
		/* Button Reset */
		border: none;
		margin: 0;
		padding: 0;
		width: auto;
		overflow: visible;

		background: transparent;

		box-shadow: inherit;

		/* inherit font & color from ancestor */
		color: inherit;
		font: inherit;

		/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
		line-height: normal;

		/* Corrects font smoothing for webkit */
		-webkit-font-smoothing: inherit;
		-moz-osx-font-smoothing: inherit;

		/* Corrects inability to style clickable `input` types in iOS */
		-webkit-appearance: none;

		&:hover {
			background: none;
			box-shadow: none;
		}

		&.default {
			background-color: #fff;
			border-radius: 4px;
			box-sizing: border-box;
			padding: 10px;
			letter-spacing: 1px;
			font-family: Open Sans,sans-serif;
			font-weight: 400;
			min-width: 140px;
			margin-top: 8px;
			color: #8b8c8d;
			cursor: pointer;
			border: 1px solid #dddedf;
			text-transform: uppercase;
			transition: all .1s;
			font-size: 11px;
			outline: none;

			&:hover {
				background-color: darken(#FFF, 5%)
			}
		}

		&.primary {
			background-color: #1697a1;
			border-radius: 4px;
			box-sizing: border-box;
			padding: 10px;
			letter-spacing: 1px;
			font-family: Open Sans,sans-serif;
			font-weight: 400;
			min-width: 140px;
			margin-top: 8px;
			color: #FFF;
			cursor: pointer;
			border: 1px solid #dddedf;
			text-transform: uppercase;
			transition: all .1s;
			font-size: 11px;
			outline: none;

			&:hover {
				background-color: darken(#1697a1, 10%)
			}
		}
	}
}

.v-step {
	z-index:999 !important;
}